import Routing from "../Routing/Routing";
import Navbar from "../Navbar/navbar";
import { useContext, useState } from "react";
import { UserContext } from "../../../Redux/UserContext";
import jwtDecode from "jwt-decode";
import "./Layout.css";
import { useEffect } from "react";
import authFunctions from "../../../Services/AuthFunctions";
import Loading from "../../SharedArea/Loading/Loading";
import { useDispatch } from "react-redux";
import { fetchOrders } from "../../../Redux/slicers/ordersSlicer";
import { OrdersDispatch, TasksDispatch } from "../../../Redux/OrdersContext";
import { fetchTasks } from "../../../Redux/slicers/tasksSlicer";

function Layout(): JSX.Element {
	interface TokenPayload {
		user_id: number;
	}

	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isLoadedProfile, setIsLoadedProfile] = useState<boolean>(false);
	const context = useContext(UserContext);
	const orderDispatch: OrdersDispatch = useDispatch();
	const tasksDipsatch: TasksDispatch = useDispatch();
	
	document.addEventListener('focusin', (event: FocusEvent) => {
		const target = event.target as HTMLInputElement | HTMLTextAreaElement;
		if ((target.tagName === 'INPUT' && target.type !== 'checkbox') || target.tagName === 'TEXTAREA') {
			setTimeout(() => {
				const value = target.value;
				target.setSelectionRange(value.length, value.length);
			}, 0); // Ensure this happens after focus
		}
	});
	
	// document.addEventListener('mousedown', (event: MouseEvent) => {
	// 	const target = event.target as HTMLInputElement | HTMLTextAreaElement;
	// 	if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
	// 		event.preventDefault(); // Prevents default click behavior
	// 		target.focus(); // Ensures the element is focused
	// 		const value = target.value;
	// 		target.setSelectionRange(value.length, value.length); // Moves cursor to the end
	// 	}
	// });

	useEffect(() => {
		const token = localStorage.getItem("tokens");
		if (token) {
			const accessToken = JSON.parse(
				localStorage.getItem("tokens")
			).access;
			const container = jwtDecode<TokenPayload>(accessToken);
			authFunctions
				.getUserById(container.user_id)
				.then((userResponse) => (context.user = userResponse))
				.then(() => {
					authFunctions
						.getUserProfileById(context.user.id)
						.then((profile) => (context.profile = profile))
						.finally(() => setIsLoadedProfile(true));
				})
				.then(() => {
					orderDispatch(fetchOrders());
					tasksDipsatch(fetchTasks());
				})
				.finally(() => {
					setIsLoaded(true);
				});
		} else {
			setIsLoaded(true);
			setIsLoadedProfile(true);
		}
	}, [context, orderDispatch, tasksDipsatch]);

	if (!isLoaded || !isLoadedProfile) {
		return <Loading />;
	}

	return (
		<div className="Layout" id="Layout">
			<header>
				<Navbar></Navbar>
			</header>
			<main>
				<Routing></Routing>
			</main>
		</div>
	);
}

export default Layout;
